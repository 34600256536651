import * as React from 'react'
import Layout from "../components/layout/layout";

const Features = () => {
    return (
        <Layout pageTitle="Features" pageKeywords="features, stability, performance, hosting" pageDescription="BeltHosting Features">
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-8">
                    <div className="page">
                        <div className="title">
                            Features
                        </div>
                        <div className="content">
                            <p>Usage and Collocation</p>
                            <ul>
                                <li>
                                    We have our rack collocated at DimeNoc Datacenter. 20U!
                                </li>
                                <li>
                                    We use WHMCS billing software
                                </li>
                                <li>
                                    For managing hosting, we use WHM/cPanel on Apache httpd web server.
                                </li>
                                <li>Our servers are highly engineered, and our focus is on speed, energy and
                                    stability.
                                </li>
                                <li>Our co-owned website xShellz is free and the commercial website dedicated to linux
                                    accounts, IRCD, bouncers, IPv4 & IPv6, programming and IRC protocols. For more info
                                    visit <a href="https://www.xshellz.com">www.xShellz.com</a></li>
                            </ul>
                            <p>Speed Test</p>
                            <ul>
                                <li><a href="https://testfiles.belthosting.com/10mb.test">Download 10 MB (Webserver US
                                    #1)</a></li>
                                <li><a href="https://testfiles.belthosting.com/100mb.test">Download 100 MB (Webserver US
                                    #1)</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"/>
            </div>
        </Layout>
    )
}
export default Features
